.lots {
  .gg {
  }

  position: relative;
}

.add {
  cursor: pointer;
  position: absolute;
  right: 50%;
  bottom: -100px;
  transform: translateX(-100%)
}