.footer {
  display: flex;
  align-items: flex-end;
  padding-top: 40px;
  border-top: 2.66375px solid #ECE7EE;;
  margin-top: -11px;
  width: 100%;
  .logo{
   width: 230px;
   margin-right: 72px;
  }
  .topText {
    font-weight: 500;
    font-size: 20px;
    line-height: 106%;
    display: flex;
    align-items: flex-end;
    color: #9116cb;
    margin-bottom: 8px;
    margin-right: 72px;
  }
  .bottomText {
    font-weight: 500;
    font-size: 30px;
    line-height: 106%;
    display: flex;
    align-items: center;
    color: #300440;
    margin-right: 72px;
  }
}
