.InputText {
    font-size: inherit !important;
    color: inherit !important;
    padding: inherit !important;
    margin: inherit !important;
    outline: none !important;
    border: none !important;
    font-weight: inherit !important;
    background: inherit !important;
    border-bottom: dashed 2px var(--second-color) !important;
    word-wrap: inherit !important;
}