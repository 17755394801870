.App{
  width: 1357px;
  min-width: 1357px;
  max-width: 1357px;

  height: auto;
  min-height: 1918px;

  padding: 80px 80px 64px 80px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: #F9F9F9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
}

.top{
  font-weight: 500;
font-size: 48px;
line-height: 120%;
letter-spacing: -0.015em;
color: #300440;
margin-top: 57px;
margin-bottom: 56px;
max-width: 60%;
}

.tt{
  font-weight: 500;
font-size: 40px;
line-height: 120%;
display: flex;
align-items: flex-end;
letter-spacing: -0.015em;
color: #300440;
padding-bottom: 28px;
}

.Download {
  cursor: pointer;
  margin: 0 50%;
}