.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    .text{
        font-weight: 400;
        font-size: 22px;
        line-height: 26px;
        text-align: right;
        color: var(--second-color);
        margin-top: -12px;
    }
}