@font-face {
  font-family: 'Factor A';
  src: url('/public/fonts/FactorALight-Regular.eot');
  src: local('Factor A Light Regular'), local('FactorALight-Regular'),
      url('/public/fonts/FactorALight-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/FactorALight-Regular.woff2') format('woff2'),
      url('/public/fonts/FactorALight-Regular.woff') format('woff'),
      url('/public/fonts/FactorALight-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Factor A';
  src: url('/public/fonts/FactorA-Regular.eot');
  src: local('Factor A Regular'), local('FactorA-Regular'),
      url('/public/fonts/FactorA-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/FactorA-Regular.woff2') format('woff2'),
      url('/public/fonts/FactorA-Regular.woff') format('woff'),
      url('/public/fonts/FactorA-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Factor A';
  src: url('/public/fonts/FactorAMedium-Regular.eot');
  src: local('Factor A Medium Regular'), local('FactorAMedium-Regular'),
      url('/public/fonts/FactorAMedium-Regular.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/FactorAMedium-Regular.woff2') format('woff2'),
      url('/public/fonts/FactorAMedium-Regular.woff') format('woff'),
      url('/public/fonts/FactorAMedium-Regular.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Factor A';
  src: url('/public/fonts/FactorA-Bold.eot');
  src: local('Factor A Bold'), local('FactorA-Bold'),
      url('/public/fonts/FactorA-Bold.eot?#iefix') format('embedded-opentype'),
      url('/public/fonts/FactorA-Bold.woff2') format('woff2'),
      url('/public/fonts/FactorA-Bold.woff') format('woff'),
      url('/public/fonts/FactorA-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
 
body {
  margin: 0;
  font-family: 'Factor A';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #F9F9F9;
}
html {
  --main-color: #300440;
  --second-color: #9116CB;
  --text-color: #7E6786;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {margin: 0; padding: 0;}

