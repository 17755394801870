.total {
  display: flex;
  justify-content: space-between;
  border-bottom: 1.58px solid #ece7ee;
  padding: 24px 39.5px;
  &:last-child {
    border-bottom: none;
  }
  .name {
    font-weight: 500;
    font-size: 24px;
    line-height: 90%;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #300440;
  }
  .content {
    font-weight: 700;
    font-size: 24px;
    line-height: 90%;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: -0.015em;
    color: #9116cb;
  }
}
